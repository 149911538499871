body {
  margin: 0;
  padding: 0;
  color: #f0f;
  background-color: #333;
}

footer {
  background-color: #333;
  border-top: 3px solid #f0f;
  text-align: right;
  padding: 10px 20px;
}

h1 {
  margin: 0;
  padding-top: 0.67em;
}

h2 {
  margin: 0;
  margin-bottom: 0.67em;
}
.App {
  background-color: #000;
  text-align: center;
}


nav {
  background-color: #333;
  border-bottom: 3px solid #f0f;
  text-align: center;
}

nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

nav li {
  display: inline-block;
  padding: 10px 20px;
}

nav a {
  font-size: calc(8px + 2vmin);
}

nav a,
footer a {
  text-decoration: none;
  color: #f0f;
}

nav a:hover,
footer a:hover {
  color: #fff;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
 
.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-header button {
  color: #f0f;
  background-color: #000;
  padding: 20px 50px;
  margin-bottom: 20px;
  border: 5px solid #f0f;
  border-radius: 10px;
  font-size: calc(10px + 2vmin);
  cursor: pointer;
}

.App-header .connectButton {
  margin-top: 20px;
}

.App-header .inputDiv {
  position: relative;
  color: #f0f;
  background-color: #333;
  padding: calc(30px + 2vmin) 5px;
  border: 5px solid #f0f;
  border-radius: 10px;
  font-size: calc(10px + 2vmin);
  margin-bottom: 20px;
}

.App-header .inputDiv input {
  color: #f0f;
  text-align: right;
  background-color: #333;
  border: 0;
  font-size: calc(20px + 2vmin);
  width: 50vw;
}
.App-header .inputDiv .inputToken {
  color: #faf;
  position: absolute;
  top: 5px;
  left: 5px;
}
.App-header .inputDiv .priceInfo {
  color: #faf;
  font-size: calc(4px + 1vmin);
  text-align: left;
  position: absolute;
  bottom: 5px;
  left: 5px;
}
.App-header .inputDiv .inputQties {
  position: absolute;
  bottom: 5px;
  right: 5px;
}

.App-header .inputDiv .inputQties a {
  color: #faf;
  text-decoration: none;
  margin-left: 5px;
  font-size: calc(5px + 2vmin);
}

.App-header .inputDiv .inputQties a:hover {
  color: #F0F;
}

.App-header p {
  color: #f0f;
  font-size: calc(5px + 2vmin);
}

.App-link {
  color: #f0f;
}

.network-error {
  padding: 20px;
}